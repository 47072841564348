<template>
  <div class="ys-progressbar">
    <div class="progress" :style="`width: ${progressWidth}%;`"></div>
  </div>
</template>

<script>
export default {
  name: "YsProgressbar",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    progressWidth() {
      return 100 * (this.progress - this.min) / (this.max - this.min);
    }
  }
}
</script>

<style scoped>

.ys-progressbar {
  height: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #f091a6;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: 0px;
  max-width: 100%;
}

</style>